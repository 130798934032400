import React, { lazy, useState, useEffect, Suspense } from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { QUERY } from '../apollo/queries';
import config from '../config';
import fetch from 'isomorphic-unfetch';
import 'core-js/es/array/iterator';
import 'core-js/es/array/find';

import styled from 'styled-components';
import { COLOR } from '../theme/constants';
import NavigationMenu from '../components/navMenu';
import NavigationMenuItem from '../components/navMenuItem';
import Loading from '../components/loading';
import { isShowServiceDebugInfo, showServiceDebugInfo } from '@insiderintelligence/debuginfo';

const AssetList = lazy(() => import('../components/assetList'));
const AssetListByMonth = lazy(() => import('../components/assetListByMonth'));

export default function NoteworthyContent() {
  const [selectedMenu, setSelectedMenu] = useState('New');
  const [data, setData] = useState({
    latestAssets: [],
    upcomingAssetMonths: []
  });
  const [apolloQuery, setApolloQuery] = useState(QUERY);

  const client = new ApolloClient({
    link: new HttpLink({
      uri: `${config.noteworthyContentApiUrl}/graphql/`,
      credentials: 'same-origin',
      fetch
    }),
    cache: new InMemoryCache()
  });

  useEffect(() => {
    //#region DEBUG-INFO
    // expose service & components version/debug info if non-prod environment
    isShowServiceDebugInfo(config.currentEnvironment) &&
      showServiceDebugInfo({
        serviceName: config.serviceName || '',
        releaseVersion: config.debugVersion || ''
      });
    //#endregion DEBUG-INFO
  }, []);

  useEffect(() => {
    client
      .query({
        query: apolloQuery
      })
      .then(result => {
        setData(result.data.noteworthyAssets);
      });
  }, [apolloQuery]);

  const menuSelect = menu => {
    setSelectedMenu(menu);
  };

  const enableUpcomingContent = config.enableUpcomingContent.toString() === 'true';

  return (
    <NoteworthyContentWrapper>
      <NavigationMenu>
        <NavigationMenuItem onClick={() => menuSelect('New')} name={'New'} selected={selectedMenu === 'New'} />
        {enableUpcomingContent ? (
          <NavigationMenuItem onClick={() => menuSelect('Upcoming')} name={'Upcoming'} selected={selectedMenu === 'Upcoming'} />
        ) : null}
      </NavigationMenu>

      <Content>
        {selectedMenu === 'New' && data.latestAssets && data.latestAssets.length > 0 ? (
          <Suspense fallback={<Loading />}>
            <AssetList assets={data.latestAssets} />
          </Suspense>
        ) : null}
        {selectedMenu === 'Upcoming' && data.upcomingAssetMonths && data.upcomingAssetMonths.length > 0 ? (
          <Suspense fallback={<Loading />}>
            <AssetListByMonth months={data.upcomingAssetMonths} />
          </Suspense>
        ) : null}
      </Content>
    </NoteworthyContentWrapper>
  );
}

const NoteworthyContentWrapper = styled.div`
  background-color: ${COLOR.WHITE};
`;

const Content = styled.div`
  height: 475px;
`;
